import { GALLERY_IMAGE } from '../interfaces/gallery-img';

export const images: GALLERY_IMAGE[] = [{
    url: "./assets/img/gallery/our-work-01.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-01.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-02.jpg",
    altText: 'Lawn and hedge trim',
    title: 'Lawn and hedge trim',
    thumbnailUrl: "./assets/img/gallery/our-work-02.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-03.jpg",
    altText: 'Trees trimmed',
    title: 'Trees trimmed',
    thumbnailUrl: "./assets/img/gallery/our-work-03.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-04.jpg",
    altText: 'Trees trimmed',
    title: 'Trees trimmed',
    thumbnailUrl: "./assets/img/gallery/our-work-04.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-05.jpg",
    altText: 'Hedges trimmed',
    title: 'Hedges trimmed',
    thumbnailUrl: "./assets/img/gallery/our-work-05.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-06.jpg",
    altText: 'Tree shaped',
    title: 'Tree shaped',
    thumbnailUrl: "./assets/img/gallery/our-work-06.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-07.jpg",
    altText: 'Another tree trimmed and shaped',
    title: 'Another tree trimmed and shaped',
    thumbnailUrl: "./assets/img/gallery/our-work-07.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-08.jpg",
    altText: 'Tidied garden',
    title: 'Tidied garden',
    thumbnailUrl: "./assets/img/gallery/our-work-08.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-09.jpg",
    altText: 'Green is our favourite colour; garden trimmed.',
    title: 'Green is our favourite colour; garden trimmed.',
    thumbnailUrl: "./assets/img/gallery/our-work-09.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-10.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-10.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-11.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-11.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-12.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-12.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-13.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-13.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-14.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-14.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-15.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-15.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-16.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-16.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-17.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-17.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-18.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-18.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-19.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-19.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-20.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-20.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-21.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-21.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-22.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-22.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-23.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-23.jpg"
  },
  {
    url: "./assets/img/gallery/our-work-24.jpg",
    altText: 'Garden after work done',
    title: 'Garden after work done',
    thumbnailUrl: "./assets/img/gallery/our-work-24.jpg"
  }
];
