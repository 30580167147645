import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AcaciaServicesComponent } from './acacia-services/acacia-services.component';
import { AboutComponent } from './about/about.component';
import { GalleryComponent } from './gallery/gallery.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'services', component:  AcaciaServicesComponent},
  { path: 'about', component:  AboutComponent},
  { path: 'gallery', component:  GalleryComponent},
  { path: 'testimonials', component:  TestimonialsComponent},
  { path: 'contact', component:  ContactComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
