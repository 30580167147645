import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxImageGalleryComponent } from 'ngx-image-gallery';
import { GALLERY_CONF } from '../interfaces/gallery-conf';
import { images } from './gallery.images';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;

  // gallery configuration
  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: true,
    inline: true,
    backdropColor: 'EFEBE9',
    showCloseControl: false,
    reactToMouseWheel: false,
    showArrows: true
  };

  images = images;

  constructor() {}

  ngOnInit() {
    this.openGallery();
  }

  // METHODS
  // open gallery
  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.ngxImageGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.ngxImageGallery.prev();
  }

}
